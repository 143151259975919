import { Breadcrumb, Table, Typography, Tooltip } from "antd"
import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { itemRender } from "../../customUtil/table"
import { getAllOrder } from "../../services/user"
import Filter from "./Filter"
import _ from "lodash"
import {
  fulfillmentStatusMap,
  getShippingAddress,
  japaneseDateConvert,
  orderStatusMap,
} from "../../customUtil/global"
import "./style.scss"
import { Content } from "antd/lib/layout/layout"

import queryString from "query-string"
import { paginationCustom } from "../../components/paginationCustom"
import moment from "moment"

const { Title } = Typography

const paddingZero = (numb, size) => {
  const numbSize = _.toString(numb).length
  if (numbSize < size) {
    const leadingZero = size - numbSize
    const leadingZeroStr = new Array(leadingZero).fill(0).join("")
    return `${leadingZeroStr}${_.toString(numb)}`
  }
  if (numbSize > size) {
    return `0${numb}`
  }
}

const OrderManagement = ({ location }) => {
  const [loading, setLoading] = useState(false)
  const [tableParams, setTableParams] = useState(null)
  const [total, setTotal] = useState(0)

  const [dataOrder, setDataOrder] = useState(null)

  const columns = [
    {
      title: (
        <div className="text-center flex flex-col">
          <span>問い合わせ番号</span>
          <span>注文番号</span>
        </div>
      ),
      width: 120,
      dataIndex: "display_id",
      key: "display_id",
      render: (displayId) => (
        <div style={{ color: "#888888", display: "flex" }}>
          {paddingZero(displayId, 9)}
        </div>
      ),
    },
    {
      title: <div className="text-center">問合せ日時</div>,
      dataIndex: "created_at",
      key: "created_at",
      width: 140,
      render: (text) => <div style={{ color: "#888888" }}>{text}</div>,
    },
    {
      title: <div className="text-center">ステータス</div>,
      dataIndex: "orderStatus",
      key: "orderStatus",
      width: 130,
      render: (status) => (
        <div style={{ ...status?.style }}>{status?.display}</div>
      ),
    },
    {
      title: <div className="text-center">対応状況</div>,
      dataIndex: "fulfillmentStatus",
      key: "fulfillmentStatus",
      width: 130,
      render: (status) => (
        <div style={{ ...status?.style }}>{status?.display}</div>
      ),
    },
    {
      title: <div className="text-center">出品企業名</div>,
      width: 140,
      dataIndex: "PCN",
      key: "PCN",
      render: (text) => <div style={{ color: "#222222" }}>{text}</div>,
    },
    {
      title: <div className="text-center">発注企業名</div>,
      width: 140,
      dataIndex: "customer",
      key: "customer",
      render: (text) => <div style={{ color: "#222222" }}>{text}</div>,
    },
    {
      title: <div className="text-center">発注企業住所</div>,
      width: 150,
      dataIndex: "shippingAddress",
      key: "shippingAddress",
      render: (text) => <div style={{ color: "#222222" }}>{text}</div>,
    },
    {
      title: <div className="text-center">決済方法</div>,
      width: 90,
      dataIndex: "payment_type",
      key: "payment_type",
      render: (text) => (
        <div style={{ color: "#222222" }}>{text?.toUpperCase()}</div>
      ),
    },
    {
      title: <div className="text-center">金額</div>,
      width: 100,
      dataIndex: "total_price",
      key: "total_price",
      render: (total) => (
        <div style={{ color: "#222222" }}>
          {`${new Intl.NumberFormat("ja-JP", {}).format(total)}円`}
        </div>
      ),
    },
    // {
    //   title: <div className="text-center">メッセージ</div>,
    //   width: 300,
    //   dataIndex: "message",
    //   key: "message",
    //   render: (text) => <div style={{ color: "#222222" }}>{text}</div>,
    // },
  ]

  const handleTableChange = (pagination, filters, sorter) => {
    const query = queryString.parse(location.search)
    const newQueryStr = {
      ...query,
      pageCurrent: pagination.current,
    }
    delete newQueryStr.sorterField
    delete newQueryStr.order

    if (sorter.order) {
      newQueryStr.sorterField = sorter.field
      newQueryStr.order = sorter.order
    }
    navigate(
      `/order-management/?${queryString.stringify(newQueryStr)}`,
      undefined,
      {
        shallow: false,
      }
    )
  }

  useEffect(() => {
    if (tableParams) {
      ;(async function () {
        const query = queryString.parse(location.search)
        setLoading(true)
        const dataSent = {
          "created_at[gt]": query?.start_date
            ? moment(query?.start_date).format("YYYY-MM-01")
            : null,
          "created_at[lt]": query?.end_date
            ? moment(query?.end_date).format("YYYY-MM-01")
            : null,
          offset:
            (tableParams.pagination.current - 1) *
            tableParams.pagination?.pageSize,
          limit: tableParams.pagination.pageSize,
          status: query?.status,
          q: query?.keyword,
        }
        const res = await getAllOrder({
          params: { ...dataSent },
        })
        if (res.data) {
          if (
            res?.data?.count &&
            Math.ceil(res?.data?.count / 10) < Number(query?.pageCurrent)
          ) {
            const newQueryStr = queryString.stringify({
              ...query,
              pageCurrent: 1,
            })
            navigate(`/order-management/?${newQueryStr}`, undefined, {
              shallow: true,
            })
            return
          }
          const data = res.data.orders.map((order, i) => {
            const payment = order.parent.payments?.[0]
            return {
              display_id: order?.display_id,
              order_id: order?.id,
              created_at: japaneseDateConvert(order.created_at),
              PCN: order?.store?.name,
              customer: order?.customer?.user?.metadata?.company_name,
              orderStatus: orderStatusMap[order?.status],
              fulfillmentStatus:
                fulfillmentStatusMap[order?.fulfillment_status],
              shippingAddress: getShippingAddress(order),
              payment_type: payment?.provider_id,
              total_price: order?.total,
              message:
                "問い合わせ内容テキスト問い合わせ内容テキスト問い合わ...",
            }
          })
          setDataOrder(data)
          setTotal(res.data.count)
        }
        setLoading(false)
      })()
    }
  }, [tableParams])
  useEffect(() => {
    const query = queryString.parse(location.search)
    if (
      Number(query?.pageSize) &&
      Number(query?.pageCurrent) &&
      query?.pageSize == "10"
    ) {
      setTableParams({
        pagination: {
          current: Number(query?.pageCurrent),
          pageSize: 10,
        },
      })
    } else {
      const newQueryStr = queryString.stringify({
        pageSize: 10,
        pageCurrent: 1,
      })
      navigate(`/order-management/?${newQueryStr}`)
    }
  }, [location])
  return (
    <div>
      <Content>
        {" "}
        <div
          style={{
            backgroundColor: "#FFFFFF",
            padding: "10px",
          }}
        >
          <Breadcrumb separator={">"}>
            <Breadcrumb.Item>
              <Link to="/home">HOME</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="!text-[#888888]">
              販売管理
            </Breadcrumb.Item>
          </Breadcrumb>
          <Title
            level={2}
            className="!text-2xl  !pt-6 !font-normal !leading-[33px]] !tracking-[0.3px]"
          >
            販売管理
          </Title>
        </div>
        <Filter location={location} />
        {tableParams && (
          <Table
            className="styleTable"
            columns={columns}
            dataSource={dataOrder}
            scroll={{ x: 2500 }}
            loading={loading}
            onChange={handleTableChange}
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  navigate(
                    `/order-management/detail?&orderId=${record?.order_id}`
                  )
                },
              }
            }}
            pagination={{
              ...tableParams.pagination,
              total: total,
              itemRender: paginationCustom,
              showSizeChanger: false,
              showTotal: (total, range) => (
                <div>
                  {range[0]}~{range[1]} 件 表示 &nbsp;&nbsp;&nbsp; (全
                  {total}件)
                </div>
              ),
            }}
          />
        )}
      </Content>
    </div>
  )
}

export default OrderManagement
